<template>
  <section class="bg-red-200 text-green-900 relative">
    <div class="min-h-screen hero-image bg-right-bottom bg-cover flex justify-center items-center"
         :style="{ backgroundImage: 'url(' + require('@/assets/bio.jpg') + ')', backgroundSize: 'contain' }">
      <div class="relative container mx-auto p-4 flex items-center z-10">
        <div>
          <div class="content float-left px-5">
            <div class="heading mb-3 text-5xl text-white lg:text-5xl text-right">Store</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="px-2 py-32 bg-white md:px-0">
    <div class="container items-center max-w-8xl px-8 mx-auto xl:px-5">
      <div class="flex flex-wrap items-center sm:-mx-4">
        <div class="container mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">

            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="400" style="width:100%;max-width:660px;overflow:hidden;background:transparent;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/remember-the-time/1361316125"></iframe>
              </div>
            </div>

            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/1s28avafiKXS0Dzsi0MpzE?utm_source=generator" width="100%" height="400" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
              </div>
            </div>

            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe title="deezer-widget" src="https://widget.deezer.com/widget/dark/album/73344052" width="100%" height="400" frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "web-store"
}
</script>

<style lang="scss" scoped>
.clear-left {
  clear: left;
}

.rounded {
  border-radius: 1.25rem !important;
}

.min-h-screen {
  min-height: 50vh !important;
}

.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #070707 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
  width: 100%;
  content: '';
}
</style>