<template>
  <section class="bg-black-200 text-green-900 relative">
    <div class="min-h-screen hero-image bg-right-bottom bg-cover sm:flex-row flex-col"
         :style="{ backgroundImage: 'url(' + require('@/assets/1.jpg') + ')' }">
    </div>
  </section>

  <section class="px-2 py-32 bg-white md:px-0">
    <div class="container items-center max-w-6xl px-8 mx-auto xl:px-5">
      <div class="flex flex-wrap items-center sm:-mx-3">
        <div class="w-full md:w-1/2 md:px-3">
          <div
              class="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
            <p class="font-bold text-gray-700 sm:text-2xl">
              <span class="block xl:inline">
                Actor, Dupree Koual is best known for the lead role in the short film "Run" in 2019 where he played the
                character Bobby, and the more recent release, "Stripped" in 2022.
              </span>
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              Actor, Dupree Koual is best known for the lead role in the short film Run in 2019 where he played the
              character Bobby, and the more recent release, Stripped in 2022.
              He has also co-starred with Gerard Essomba and Alphonse Beni in the movie Les Veuves Volontaire (Voluntary
              Widows) in 2011.
            </p>
            <div class="relative flex flex-col sm:flex-row sm:space-x-4">
              <router-link to="/bio"
                           class="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
                Read More
              </router-link>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <div class="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
            <img src=@/assets/about.jpg alt="about"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container items-center max-w-6xl px-8 mx-auto xl:px-5">
    <div id="gallery" class="relative w-full" data-carousel="slide">
      <!-- Carousel wrapper -->
      <div class="relative h-90 overflow-hidden rounded-lg md:h-96">
        <!-- Items -->
        <div v-for="item in 19" :key="item" class="hidden duration-700 ease-in-out" data-carousel-item>
          <img :src="require(`@/assets/gallery/samba/${item}.jpg`)"
               class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="">
        </div>
      </div>
      <!-- Slider controls -->
      <button type="button"
              class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              data-carousel-prev>
        <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 1 1 5l4 4"/>
            </svg>
            <span class="sr-only">Previous</span>
        </span>
      </button>
      <button type="button"
              class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              data-carousel-next>
        <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
      </button>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'web-home',
  components: {},
  data() {

  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.clear-left {
  clear: left;
}

.min-h-screen {
  min-height: 87vh !important;
}

.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(23, 23, 23, 0) 0, #181818 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  content: '';
}
</style>
