<template>
  <section class="bg-red-200 text-green-900 relative">
    <div class="min-h-screen hero-image bg-right-bottom bg-cover flex justify-center items-center"
         :style="{ backgroundImage: 'url(' + require('@/assets/bio.jpg') + ')', backgroundSize: 'contain' }">
      <div class="relative container mx-auto p-4 flex items-center z-10">
        <div>
          <div class="content float-left px-5">
            <div class="heading mb-3 text-5xl text-white lg:text-5xl text-right">Resume</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="px-2 py-32 bg-white md:px-0">
  <div class="container items-center max-w-6xl px-8 mx-auto xl:px-5">
    <div class="flex flex-wrap items-center sm:-mx-3">
      <iframe height="900" width="1100" src="https://docs.google.com/document/d/e/2PACX-1vTMjRCCSFxscwfEP19N0QbA6wimLAHSyqTvVyxPf0JcIDIBvry4tNM1ElrPb2pFMg/pub?embedded=true"></iframe>
    </div>
  </div>
  </section>
</template>

<script>
export default {
  name: "web-resume"
}
</script>

<style lang="scss" scoped>
  .clear-left {
  clear: left;
}
.min-h-screen{
  min-height: 50vh !important;
}
.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #070707 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
  width: 100%;
  content: '';
}
</style>