<template>
 <div class="sticky top-0 z-50 bg-gray-900 text-gray-100 py-3.5 px-10 shadow md:flex justify-between items-center">
    <div class="flex items-center cursor-pointer">
      <span class="text-green-500 text-x1 mr-1">
        <img class="object-cover w-48"
            src="@/assets/logo.png">
      </span>
    </div>

   <span @click="MenuOpen()" class="absolute md:hidden right-6 top-3 cursor-pointer text-4x1">
    <i :class="[open ? 'bi bi-x': 'bi bi-filter-left']"></i>
   </span>

   <ul class="md:flex md:items-center md:px-0 px-10 md:pb-0 pb-10 md:static absolute bg-gray-900 md:w-auto
      w-full top-13 duration-700 ease-in"
      :class="[open ? 'left-0': 'left-[-100%]']">
     <li class="md:mx-4 md:my-0 my-6" v-for="link in Links" :key="link">
       <router-link :to="link.link" class="text-x1 hover:text-green-500">{{ link.name }}</router-link>
     </li>

     <Button>
       <router-link :to="`/store`" class="text-x1 hover:text-white-500">
           <i class="bi bi-cart2"></i>
           Store
       </router-link>
     </Button>
   </ul>

 </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Button from '../components/Button'
export default {
  name: "web-nav",
   components: {Button},
  setup() {
    let open = ref(false)
    let Links = [
        {name: "Home", link: "/"},
        {name: "Bio", link: "/bio"},
        {name: "Resume", link: "/resume"},
        {name: "Gallery", link: "/gallery"},
        {name: "Media", link: "/media"},
        {name: "Contact", link: "/contact"}
    ]
    function MenuOpen(){
      open.value = !open.value
    }

    return {Links, open, MenuOpen}
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .bi{
    font-size: 35px;
  }
</style>