<template>
  <section class="bg-red-200 text-green-900 relative">
    <div class="min-h-screen hero-image bg-right-bottom bg-cover flex justify-center items-center"
         :style="{ backgroundImage: 'url(' + require('@/assets/bio.jpg') + ')', backgroundSize: 'contain' }">
      <div class="relative container mx-auto p-4 flex items-center z-10">
        <div>
          <div class="content float-left px-5">
            <div class="heading mb-3 text-5xl text-white lg:text-5xl text-right">Media</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="px-2 py-32 bg-white md:px-0">
    <div class="container items-center max-w-8xl px-8 mx-auto xl:px-5">
      <div class="flex flex-wrap items-center sm:-mx-4">
        <div class="container mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ki2NgnLPR0A" class="absolute top-0 left-0 w-full h-full"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                        encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/rNsW3Yn7mUs" class="absolute top-0 left-0 w-full h-full"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                        encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe width="560" height="315" class="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/faliC7K8Lmk" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
              </div>
            </div>
            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe width="560" height="315" class="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/LnECl4_5YIY" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
              </div>
            </div>
            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe width="560" height="315" class="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/xeETz9i9zkM" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;web-share"
                        allowfullscreen></iframe>
              </div>
            </div>
            <div class="flex justify-center text-6xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100">
              <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
                <iframe width="560" height="315" class="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/5SdaXr4fNv8" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;web-share"
                        allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "web-media"
}
</script>

<style lang="scss" scoped>
.clear-left {
  clear: left;
}

.rounded {
  border-radius: 1.25rem !important;
}

.min-h-screen {
  min-height: 50vh !important;
}

.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #070707 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
  width: 100%;
  content: '';
}
</style>