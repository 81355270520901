<template>
  <div class="bg-white-400 h-screen">
    <Navbar />
    <router-view/>
    <Footer />
  </div>
</template>

<style lang="scss">
#app {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
export default {
  components: {Footer, Navbar}
}
</script>