<template>
  <button class="bg-gray-400 hover:bg-green-500 duration-300 fonts-sm text-white
    rounded py-1.5 px-10">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "web-button"
}
</script>

<style scoped>

</style>