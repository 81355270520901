<template>
  <section class="bg-red-200 text-green-900 relative">
    <div class="min-h-screen hero-image bg-right-bottom bg-cover flex justify-center items-center"
         :style="{ backgroundImage: 'url(' + require('@/assets/bio.jpg') + ')', backgroundSize: 'contain' }">
      <div class="relative container mx-auto p-4 flex items-center z-10">
        <div>
          <div class="content float-left px-5">
            <div class="heading mb-3 text-5xl text-white lg:text-5xl text-right">Bio</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="px-2 py-32 bg-white md:px-0">
    <div class="container items-center max-w-6xl px-8 mx-auto xl:px-5">
      <div class="flex flex-wrap items-center sm:-mx-3">
        <div class="w-full md:w-1/2 md:px-3">
          <div
              class="w-full pb-4 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
            <p class="font-bold text-gray-700 sm:text-2xl">
              <span class="block xl:inline">
                Actor, Dupree Koual is best known for the lead role in the short film "Run" in 2019 where he played the
                character Bobby, and the more recent release, "Stripped" in 2022.
              </span>
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              Actor, Dupree Koual is best known for the lead role in the short film Run in 2019 where he played the
              character Bobby, and the more recent release, Stripped in 2022.
              He has also co-starred with Gerard Essomba and Alphonse Beni in the movie Les Veuves Volontaire (Voluntary
              Widows) in 2011.
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              He is widely recognized for his role in the Cameroonian TV Soap opera Bad Angel, a TV Series with six
              seasons of 312 episodes where he played the character Emannuel and Johnson.
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              These productions have garnered massive airplay and critical acclaim locally and have also created waves
              internationally. From Dupree's excellent work on more than four TV Series and movies, he is recognized as
              one of the best Actors on the continent.
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              Dupree Koual was born in Cameroon, a country located in West-Central Africa. Dupree was the 10th child
              from a family of 11 children. His father, David Koual, was a mechanical engineer and his mother, Emilienne
              Nigwe, was an interior decorator/designer. Dupree Koual grew up in Cameroon and Nigeria.
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              He grew up listening to reggae music and watching Indian movies from the library with his elder brother,
              who was also a reggae musician. Having an older brother leading the way, Dupree was easily driven into the
              art of acting and singing.
            </p>
            <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
              Dupree's career consists of being an Actor, Artist, Radio disc joker, Television Presenter, radio Manager
              MO radio (Ed), and a well-respected Social Leader.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2">
          <div class="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
            <img src=@/assets/bio-resume.jpg alt="bio-resume"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "web-bio"
}
</script>

<style lang="scss" scoped>
.clear-left {
  clear: left;
}

.min-h-screen {
  min-height: 50vh !important;
}

.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #070707 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
  width: 100%;
  content: '';
}
</style>