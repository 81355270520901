<template>
  <section class="bg-red-200 text-green-900 relative">
    <div class="min-h-screen hero-image bg-right-bottom bg-cover flex justify-center items-center"
         :style="{ backgroundImage: 'url(' + require('@/assets/bio.jpg') + ')', backgroundSize: 'contain' }">
      <div class="relative container mx-auto p-4 flex items-center z-10">
        <div>
          <div class="content float-left px-5">
            <div class="heading mb-3 text-5xl text-white lg:text-5xl text-right">Gallery</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container mx-auto px-4">
    <section class="pt-8 px-4">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div class="grid gap-4">
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/IMG-20191029-WA0040.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/7W9A3631.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/7W9A3633.jpg" alt="">
          </div>
        </div>
        <div class="grid gap-4">
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/7W9A3636.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/7W9A3638.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/DSC_7663zet11é.jpg" alt="">
          </div>
        </div>
        <div class="grid gap-4">
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/1.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/2.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/3.jpg" alt="">
          </div>
        </div>
        <div class="grid gap-4">
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/4.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/5.jpg" alt="">
          </div>
          <div>
            <img class="h-auto max-w-full rounded-lg"
                 src="../assets/gallery/011.jpg" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="pt-8 px-4">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div v-for="item in 19" :key="item">
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg"
                   :src="require(`@/assets/gallery/samba/${item}.jpg`)" :alt="item">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "web-gallery"
}
</script>

<style lang="scss" scoped>
.clear-left {
  clear: left;
}

.rounded {
  border-radius: 1.25rem !important;
}

.min-h-screen {
  min-height: 50vh !important;
}

.hero-image::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #070707 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
  width: 100%;
  content: '';
}
</style>